.root-doodledash .high-score_root {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.root-doodledash .high-score_title {
  background-image: url('../../assets/doodleDash/highScoreTitle.webp');
  width: 1798px;
  height: 167px;
  z-index: 1;
}

.root-doodledash .high-score_paper {
  background-image: url('../../assets/doodleDash/highScoreBackground.webp');
  width: 1589px;
  height: 856px;
  margin-top: -70px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: -856px;
  animation: high-score_paper_enter 1s forwards ease-in;
}

.root-doodledash .high-score_paper-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

@keyframes high-score_paper_enter {
  12% {
    background-position-y: -856px;
  }

  24% {
    background-position-y: -584px;
  }

  36% {
    background-position-y: -20px;
  }

  54% {
    background-position-y: -260px;
  }

  74% {
    background-position-y: -16px;
  }

  82% {
    background-position-y: -64px;
  }

  92% {
    background-position-y: -6px;
  }

  96% {
    background-position-y: -16px;
  }

  100% {
    background-position-y: 0;
  }
}

.root-doodledash .high-score_bar-container {
  animation: high-score_bar-container-enter 1s forwards;
  transform: translateX(-250vw);
  position: relative;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes high-score_bar-container-enter {
  0% {
    transform: translateX(-250vw);
  }
  50% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}

.root-doodledash .high-score_index {
  background-image: url('../../assets/doodleDash/indexFlag.webp');
  height: 93px;
  width: 112px;
  margin-right: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 60px;
}
