.root-doodledash .selfie {
  width: 130px;
  height: 130px;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  background-repeat: no-repeat;
}

.root-doodledash .selfie_name {
  text-align: center;
  white-space: pre;
  line-height: 1;
  font-size: 36px;
  position: absolute;
  bottom: -35px;
}

.root-doodledash .selfie_name-optional {
  opacity: 0.5;
}
.root-doodledash .selfie_name-dummy {
  margin-top: -60px;
}
.root-doodledash .selfie_name-dummy-spacing {
  letter-spacing: 0;
}

@keyframes FaceFloat {
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(5px);
  }
  75% {
    transform: translateY(-2.5px);
  }
}
