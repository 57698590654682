.root-doodledash .yard-and-display_root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.root-doodledash .yard-and-display_title-img {
  background-image: url('./assets/doodleDash/scanTitle.webp');
  width: 1078px;
  height: 230px;
  position: absolute;
  top: 10%;
  background-repeat: no-repeat;
  background-size: contain;
  animation: yard-and-display_title-img-enter 1s 1s forwards;
  transform: translateY(-100vh);
}

.yard-and-display_title-text {
  font-size: 42px;
  align-self: center;
  transform: translateY(-5vh);
}

@keyframes yard-and-display_title-img-enter {
  50% {
    transform: translateY(10vh) rotate(5deg);
  }
  100% {
    transform: translateY(0);
  }
}

.root-doodledash .yard-and-display_mascot {
  background-image: url('./assets/doodleDash/redMascotQR.webp');
  width: 406px;
  height: 473px;
  position: absolute;
  bottom: -1%;
  left: 19%;
  background-repeat: no-repeat;
  background-size: contain;
  animation: yard-and-display_mascot-enter 1s 1.5s forwards;
  transform: translateX(-100vw);
  opacity: 0;
}

@keyframes yard-and-display_mascot-enter {
  0% {
    opacity: 1;
  }
  50% {
    transform: translateX(10vw) rotate(-5deg);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.root-doodledash .yard-and-display_title {
  font-size: 164px;
  align-self: center;
  letter-spacing: 10px;
}

.root-doodledash .yard-and-display_qr-child-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-image: url('./assets/doodleDash/QRcode.webp');
  width: 375px;
  height: 377px;
}

.root-doodledash .yard-and-display_qr-child-container-A {
  position: relative;
}

.root-doodledash .yard-and-display_qr-child-container-B {
  position: absolute;
  right: 120px;
}

.root-doodledash .yard-and-display_qr {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 92%;
  height: 92%;
  border-radius: 4%;
}

.root-doodledash .yard-and-display_code {
  position: absolute;
  top: 370px;
  font-size: 40px;
  text-align: center;
  line-height: 1;
}

.root-doodledash .yard-and-display_buttons-container-A {
  display: flex;
  position: absolute;
  bottom: 50px;
  right: -200px;
}

.root-doodledash .yard-and-display_buttons-container-B {
  display: flex;
  position: absolute;
  bottom: 50px;
  right: -1000px;
}

.root-doodledash .yard-and-display_button-with-title-container {
  text-align: center;
  font-size: 50px;
}

.root-doodledash .yard-and-display_button {
  height: 110px;
  width: 400px;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./assets/doodleDash/btn.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.root-doodledash .yard-and-display_selected-button {
  background-image: url('./assets/doodleDash/btnSuccess.webp');
}

.root-doodledash .yard-and-display_mascot-b {
  background-image: url('./assets/doodleDash/bTestMascot.webp');
  width: 1513px;
  height: 1124px;
  position: absolute;
  top: 15%;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
  animation: yard-and-display_mascot-enter 1s 1.5s forwards;
  transform: translateX(-1513px);
  opacity: 0;
}

.root-doodledash .yard-and-display_title-img-b {
  background-image: url('./assets/doodleDash/bTestTitle.webp');
  width: 593px;
  height: 394px;
  position: absolute;
  top: 1.5%;
  right: 0.7%;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: contain;
  animation: yard-and-display_title-img-enter 1s 1s forwards;
  transform: translateY(-100vh);
}
