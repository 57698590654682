.root-doodledash .display-active-drawing-sim_title-container {
  position: absolute;
  top: 5%;
  left: 30%;
}

.root-doodledash .display-active-drawing-sim_title {
  background-repeat: no-repeat;
  background-position: center;
  width: 892px;
  height: 110px;
  display: flex;
  align-items: center;
  color: white;
}

.root-doodledash .display-active-drawing-sim_title-icon {
  margin-left: -48px;
  width: 120px;
  height: 120px;
}

.root-doodledash .display-active-drawing-sim_title-red {
  background-image: url('../../../assets/doodleDash/redDrawingTitle.webp');
}

.root-doodledash .display-active-drawing-sim_title-blue {
  background-image: url('../../../assets/doodleDash/blueDrawingTitle.webp');
}

.root-doodledash .display-active-drawing-sim_char-red {
  background: url('../../../assets/doodleDash/redMascot.webp');
  width: 315px;
  height: 408px;
}
.root-doodledash .display-active-drawing-sim_char-container {
  left: 10%;
  bottom: 5%;
  position: absolute;
}
.root-doodledash .display-active-drawing-sim_char-blue {
  background: url('../../../assets/doodleDash/blueMascot.webp');
  width: 311px;
  height: 410px;
  transform: scaleX(-1);
}

.root-doodledash .display-active-drawing-sim_char-speech {
  position: absolute;
  left: -26%;
  top: -24%;
  font-size: 50px;
  width: 449px;
  height: 176px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../../assets/doodleDash/speechBubble.webp');
  display: flex;
  justify-content: center;
  align-items: center;
  animation: display-active-drawing-sim_char-speech-change 1s;
}

@keyframes display-active-drawing-sim_char-speech-change {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: rotate(5deg);
  }
}

.root-doodledash .display-active-drawing-sim_char-speech-img {
  margin-top: -30px;
  margin-left: -110px;
  margin-right: 10px;
  width: 150px;
  height: 150px;
}

.root-doodledash .display-active-drawing-sim_char-speech-text {
  margin-top: -30px;
}

.root-doodledash .display-active-drawing-sim_draw {
  position: absolute;
  right: 35%;
  top: -100%;
  animation: display-active-drawing-sim_draw-enter 1s forwards;
}

@keyframes display-active-drawing-sim_draw-enter {
  50% {
    top: 36%;
  }
  100% {
    top: 31%;
  }
}

.root-doodledash .display-active-drawing-sim_category {
  width: 100%;
  height: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  text-align: center;
  bottom: -9%;
  color: white;
  display: flex;
  justify-content: center;
}

.root-doodledash .display-active-drawing-sim_category-red {
  background-image: url('../../../assets/doodleDash/catgoryRed.webp');
}

.root-doodledash .display-active-drawing-sim_category-blue {
  background-image: url('../../../assets/doodleDash/categoryBlue.webp');
}

.root-doodledash .display-active-drawing-sim_clock-container {
  background-size: contain;
  width: 220px;
  height: 220px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  right: 100px;
  top: 400px;
  animation: display-active-drawing-sim_clock-container-enter 2s;
}

@keyframes display-active-drawing-sim_clock-container-enter {
  0% {
    right: -220px;
    transform: scale(1);
  }
  50% {
    right: 100px;
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
}

.root-doodledash * {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.root-doodledash :root {
  --bg: #17181c;
  --fg: #eef0f3;
  --primary: #255ff4;
  font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
}

.root-doodledash .display-active-drawing-sim_success {
  animation: display-active-drawing-sim_success 2s;
  transform: scale(0);
  position: absolute;
  font-size: 120px;
  text-align: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes display-active-drawing-sim_success {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1) rotate(10deg);
  }
  80% {
    transform: scale(1);
  }
}

.root-doodledash .confetti-internal {
  animation: xxx 2s;
  transform: scale(0);
  position: absolute;
  top: 0;
  left: 0;
  width: 1894px;
  height: 1057px;
  background-image: url('../../../assets/winner/confetti.webp');
  text-align: center;
}

.root-doodledash .confetti-internal_top_left {
  top: -20%;
  left: 10%;
}

@keyframes xxx {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1.2) rotate(5deg);
  }
  30% {
    transform: scale(0.9) rotate(-3deg);
  }
  50% {
    transform: scale(1.1) rotate(2deg);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0);
    opacity: 0;
  }
}
