.root-doodledash .drawing-container_top {
  position: absolute;
  top: -5%;
  width: 130%;
  height: 10%;
  left: -15%;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.root-doodledash .drawing-container_top-red {
  background-image: url('../../assets/doodleDash/redBar.webp');
}

.root-doodledash .drawing-container_top-blue {
  background-image: url('../../assets/doodleDash/redBar.webp');
}

.root-doodledash .drawing-container_body {
  border: 1px dashed #324663;
  background-color: #fff8d4;
  border-radius: 8px;
  transform: scale(1.55);
}
