.root-doodledash .stage-root_root {
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

.root-doodledash .stage-root_sentence {
  margin-top: 16px;
  min-height: 116px;
  font-size: 58px;
  width: 75%;
  line-height: 1;
}
