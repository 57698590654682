.root-doodledash .round_announcement-root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.root-doodledash .round_announcement-banner {
  background-image: url('../assets/doodleDash/roundBanner.webp');
  width: 1082px;
  height: 219px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  font-weight: bold;
  animation: round_announcment-banner-enter 1s;
}

@keyframes round_announcment-banner-enter {
  0% {
    transform: translateX(-100vw);
  }
  50% {
    transform: translateX(10vw);
  }
  100% {
    transform: translateX(0);
  }
}

.root-doodledash .round_announcement-player {
  font-size: 70px;
  animation: round_announcement-player-enter 1s 1s forwards;
  transform: translateY(-200vh);
  font-weight: bold;
}

@keyframes round_announcement-player-enter {
  0% {
    transform: translateY(-110vh);
  }
  50% {
    transform: translateY(10vh);
  }
  100% {
    transform: translateX(0);
  }
}
