.root-doodledash .scoring_transition-btn_container {
  display: flex;
  font-size: 50px;
  color: white;
  justify-content: center;
  align-items: center;
  margin-left: 200px;
  margin-top: 20px;
  transform: scale(0);
  animation: scoring_transition-btn_container_enter 0.7s forwards;
}

@keyframes scoring_transition-btn_container_enter {
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
