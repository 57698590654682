.root-doodledash .pick_team-root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.root-doodledash .pick_team-title_root {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.root-doodledash .pick_team-title {
  width: 726px;
  height: 126px;
  background-repeat: no-repeat;
  background-size: contain;
  animation: pick_team-title_enter 1s forwards;
  position: relative;
}

.root-doodledash .pick_team-title_red {
  background-image: url('../../assets/doodleDash/TeamRed.webp');
  transform: translateX(calc(-100vw - 726px)) rotate(35deg);
}

.root-doodledash .pick_team-title_blue {
  background-image: url('../../assets/doodleDash/TeamBlue.webp');
  transform: translateX(calc(100vw + 726px)) rotate(-35deg);
}

@keyframes pick_team-title_enter {
  100% {
    transform: translateX(0) rotate(0deg);
  }
}

.root-doodledash .pick_team-paper {
  background-image: url('../../assets/doodleDash/Paper.webp');
  width: 562px;
  height: 517px;
  margin-top: -35px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: -517px;
  animation: pick_team-paper_enter 1s 1s forwards ease-in;
}

@keyframes pick_team-paper_enter {
  12% {
    background-position-y: -420px;
  }

  24% {
    background-position-y: -292px;
  }

  36% {
    background-position-y: -10px;
  }

  54% {
    background-position-y: -130px;
  }

  74% {
    background-position-y: -8px;
  }

  82% {
    background-position-y: -32px;
  }

  92% {
    background-position-y: -3px;
  }

  96% {
    background-position-y: -8px;
  }

  100% {
    background-position-y: 0;
  }
}

.root-doodledash .pick_team-paper_body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  height: 75%;
  width: 100%;
  justify-content: space-evenly;
}

.root-doodledash .pick_team-player_frame {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/doodleDash/avatarFrame.webp');
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pick_team-player_enter 0.5s forwards;
  transform: scale(0);
}

.root-doodledash .pick_team-player {
  width: 86%;
  height: 86%;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  animation: pick_team-player_enter 0.5s forwards;
  transform: scale(0);
}

@keyframes pick_team-player_enter {
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.root-doodledash .pick_team-score {
  background-image: url('../../assets/doodleDash/btnSuccess.webp');
  width: 459px;
  height: 139px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 64px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 180px;
  transform: scale(0);
  animation: pick_team-player_enter forwards 0.5s;
}

.root-doodledash .pick_team-winner {
  background-image: url('../../assets/doodleDash/winnerFlag.webp');
  width: 1054px;
  height: 292px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 64px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 50px;
  transform: scale(0);
  animation: pick_team-player_enter forwards 0.5s;
}

.root-doodledash .pick_team-winner-red {
  background-image: url('../../assets/doodleDash/redWin.webp');
  width: 683px;
  height: 103px;
  margin-bottom: 50px;
}

.root-doodledash .pick_team-winner-blue {
  background-image: url('../../assets/doodleDash/blueWin.webp');
  width: 744px;
  height: 110px;
  margin-bottom: 50px;
}
